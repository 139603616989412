import { If, Notification, Spacer, Text, useForm } from '@app/components'
import { ROUTE } from '@app/data'
import { FORGOT_PASSWORD_FORM } from '@app/forms'
import { DynamicForm, generateDynamicFields } from '@app/modules'
import { EMAIL_TEMPLATES, errorHandler, sendEmail } from '@app/services'
import { useState } from 'react'

import { Logo, SignInGraphic } from '~public'

import {
  FullHeightCol,
  StyledContainer,
  StyledEmptyBoxRow,
  StyledFieldsRow,
  StyledLeftCol,
  StyledRow,
  StyledText,
} from '../elements'

export const ForgotPassword: React.FC = () => {
  const [form] = useForm()
  const [isPasswordSent, setIsPasswordSent] = useState(false)
  const [email, setEmail] = useState('')

  const [loading, setLoading] = useState(false)

  const handleSendResetPasswordEmail = async () => {
    try {
      setLoading(true)
      const data = await form.validateFields()
      setEmail(data.email)

      const continueUrl = `${window.location.origin}${ROUTE.BUY}`

      await sendEmail(EMAIL_TEMPLATES.RESET_PASSWORD, data.email, { email: data.email, continueUrl })

      Notification({ message: `Reset password email sent to ${data.email}`, type: 'success' })
      setTimeout(() => {
        setLoading(false)
        setIsPasswordSent(true)
      }, 1000)
    } catch (error) {
      Notification({ message: 'Something went wrong', type: 'error' })
      setLoading(false)
      errorHandler.report(error)
    }
  }

  return (
    <StyledContainer>
      <StyledLeftCol span={12} xs={0} sm={0} md={12}>
        <StyledEmptyBoxRow>
          <SignInGraphic />
        </StyledEmptyBoxRow>
      </StyledLeftCol>

      <FullHeightCol span={12} xs={24} sm={24} md={12}>
        <StyledFieldsRow>
          <Logo />
          <StyledRow>
            <StyledText>Forgot Your Password</StyledText>
          </StyledRow>

          <Spacer value={35} />

          <If condition={!isPasswordSent}>
            <DynamicForm
              fields={generateDynamicFields(FORGOT_PASSWORD_FORM, [{ name: 'button', loading }])}
              form={form}
              onSubmit={handleSendResetPasswordEmail}
            />
          </If>
          <If condition={isPasswordSent}>
            <Text>
              A password reset link has been sent to your email <b>{email}</b>. Please check your inbox and follow the
              instructions.
            </Text>
          </If>
        </StyledFieldsRow>
      </FullHeightCol>
    </StyledContainer>
  )
}
