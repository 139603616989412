import { auth } from '@app/services'
import {
  MultiFactorAssertion,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  TotpMultiFactorGenerator,
} from 'firebase/auth'
import { useCallback, useMemo, useRef, useState } from 'react'

export const useMfa = () => {
  const [submitting, setSubmitting] = useState(false)
  const mfaResolver = useRef<MultiFactorResolver | null>(null)
  const verificationIdRef = useRef<string>(null)
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null)
  const recaptchaContainerRef = useRef<HTMLDivElement | null>(null)
  const [hints, setHints] = useState<MultiFactorInfo[] | null>(null)
  const [selectedHint, setSelectedHint] = useState<MultiFactorInfo | null>(null)
  const hintRef = useRef<MultiFactorInfo | null>(null)

  const clearRecaptcha = () => {
    if (recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current.clear()
      recaptchaVerifierRef.current = null
    }
  }

  const initMfa = useCallback(async (resolver: MultiFactorResolver) => {
    mfaResolver.current = resolver
    setHints(resolver.hints)
  }, [])

  const startMfa = useCallback(async (hint: MultiFactorInfo) => {
    try {
      setSubmitting(true)
      setSelectedHint(hint)
      hintRef.current = hint

      if (hint.factorId === 'phone') {
        const resolver = mfaResolver.current
        recaptchaVerifierRef.current =
          recaptchaVerifierRef.current || new RecaptchaVerifier(recaptchaContainerRef.current, undefined, auth)
        const phoneInfoOptions = {
          multiFactorHint: hint,
          session: resolver.session,
        }
        const phoneAuthProvider = new PhoneAuthProvider(auth)
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifierRef.current)
        verificationIdRef.current = verificationId
      }
    } catch (err) {
      clearRecaptcha()
      throw err
    } finally {
      setSubmitting(false)
    }
  }, [])

  const completeMfa = useCallback(async (verificationCode: string) => {
    clearRecaptcha()
    setSubmitting(true)

    try {
      const resolver = mfaResolver.current
      const hint = hintRef.current

      let multiFactorAssertion: MultiFactorAssertion
      if (hint.factorId === 'phone') {
        const cred = PhoneAuthProvider.credential(verificationIdRef.current, verificationCode)
        multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      } else if (hint.factorId === 'totp') {
        multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(hint.uid, verificationCode)
      } else {
        throw new Error('Unsupported factorId')
      }
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion)
      return userCredential
    } catch (err) {
      throw err
    } finally {
      verificationIdRef.current = null
      setSubmitting(false)
    }
  }, [])

  return useMemo(
    () => ({
      submitting,
      hints,
      selectedHint,
      initMfa,
      startMfa,
      completeMfa,
      recaptchaContainerRef,
    }),
    [submitting, initMfa, startMfa, completeMfa, hints, selectedHint],
  )
}
