import { ROUTE } from '@app/data'
import { auth, EMAIL_TEMPLATES, provider, sendEmail } from '@app/services'
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'

export const useSignInSignUp = (isSignUp: boolean) => {
  const handleGoogleSignIn = async () => {
    const userCredential = await signInWithPopup(auth, provider)
    return userCredential
  }

  const handlePasswordlessSignIn = async (email: string, params?: { invite?: string }) => {
    const continueUrlBuilder = new URL(`${window.location.origin}${isSignUp ? ROUTE.ACCOUNT_REGISTRATION : ROUTE.BUY}`)
    if (params?.invite) {
      // Pass the invite code to the continue URL so the referral can be tracked
      // once the user has finished signing up after clicking the link in their email
      continueUrlBuilder.searchParams.set('invite', params.invite)
    }
    const continueUrl = continueUrlBuilder.toString()
    const templateId = isSignUp ? EMAIL_TEMPLATES.MAGIC_LINK_SIGNUP : EMAIL_TEMPLATES.MAGIC_LINK_SIGNIN

    await sendEmail(templateId, email, { continueUrl })

    try {
      window.localStorage.setItem('emailForSignIn', email)
    } catch (error) {}
  }

  const handlePasswordSignIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    return userCredential
  }

  return {
    handleGoogleSignIn,
    handlePasswordlessSignIn,
    handlePasswordSignIn,
  }
}
