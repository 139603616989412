import { If, Link, Notification, Spacer, Text, useForm } from '@app/components'
import { ROUTE } from '@app/data'
import { RESET_PASSWORD_FORM } from '@app/forms'
import { DynamicForm, generateDynamicFields } from '@app/modules'
import { auth, errorHandler } from '@app/services'
import { confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth'
import { useEffect, useMemo, useState } from 'react'

import { StyledRow, StyledText } from '../elements'

export const AuthActionPasswordReset: React.FC<{ oobCode: string; continueUrl?: string }> = ({
  oobCode,
  continueUrl,
}) => {
  const [form] = useForm<{ password: string }>()
  const [email, setEmail] = useState<string>()
  const [error, setError] = useState<string>()
  const [mode, setMode] = useState<'init' | 'password' | 'invalid' | 'user-disabled' | 'success'>('init')

  const [submitting, setSubmitting] = useState(false)

  // continueUrl is a fully qualified URL and we need to extract the location part from it
  const continueLocation = useMemo(
    () => (continueUrl ? continueUrl.substring(new URL(continueUrl).origin.length) : ROUTE.BUY),
    [continueUrl],
  )

  const handleError = (err: Error & { code: string }) => {
    const errCode = err.code
    if (errCode === 'auth/expired-action-code') {
      setMode('invalid')
      setError('The link has expired')
    } else if (errCode === 'auth/invalid-action-code') {
      setMode('invalid')
      setError('The link is invalid')
    } else if (errCode === 'auth/user-disabled' || errCode === 'auth/user-not-found') {
      setMode('user-disabled')
      setError('Cannot reset your password')
    } else if (errCode === 'auth/weak-password') {
      setMode('password')
      setError('Password is too weak')
    } else {
      setMode('invalid')
      setError('Something went wrong. Please try again')
      errorHandler.report(err)
    }
  }

  const verifyCode = async () => {
    try {
      const codeEmail = await verifyPasswordResetCode(auth, oobCode)
      setEmail(codeEmail)
      setMode('password')
    } catch (error) {
      handleError(error)
    }
  }

  useEffect(() => {
    if (mode === 'init') {
      verifyCode()
    }
  }, [mode])

  useEffect(() => {
    if (error) {
      Notification({ message: error, type: 'error' })
    }
  }, [error])

  const handleResetPassword = async () => {
    try {
      setSubmitting(true)

      let password
      try {
        const data = await form.validateFields()
        password = data.password
      } catch (err) {
        setSubmitting(false)
        return
      }

      await confirmPasswordReset(auth, oobCode, password)
      try {
        await signInWithEmailAndPassword(auth, email, password)
      } catch (err) {
        if (err.code === 'auth/multi-factor-auth-required') {
          // Ignore this error and just continue. The user will be asked to login again
          // with a 2FA code when they try to go to the continue URL.
          // The password reset is complete here, so we should show the success message regardless.
        } else {
          throw err
        }
      }

      setMode('success')
    } catch (err) {
      handleError(err)
    }
    setSubmitting(false)
  }

  return (
    <>
      <StyledRow>
        <StyledText>Reset password</StyledText>
      </StyledRow>

      <Spacer value={16} />

      <If condition={mode === 'init'}>
        <Text>Verifying code...</Text>
      </If>

      <If condition={mode === 'invalid'}>
        <>
          <Text>Invalid or expired code. Please try again.</Text>
          <Link href={ROUTE.AUTH.FORGOT_PASSWORD}>Request a new reset link</Link>
        </>
      </If>

      <If condition={mode === 'user-disabled'}>
        <Text>{error}</Text>
      </If>

      <If condition={mode === 'password'}>
        <>
          <Text>
            Enter your new password for <b>{email}</b>
          </Text>
          <Spacer value={16} />
          <DynamicForm
            fields={generateDynamicFields(RESET_PASSWORD_FORM, [{ name: 'button', loading: submitting }])}
            form={form}
            onSubmit={handleResetPassword}
          />
        </>
      </If>

      <If condition={mode === 'success'}>
        <>
          <Text>Your password has been reset.</Text>
          <Link href={continueLocation}>Continue</Link>
        </>
      </If>
    </>
  )
}
