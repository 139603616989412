import { EDynamicFormField, IField } from '@app/modules'

export const EMAIL_SIGN_IN_FORM: IField[] = [
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Enter Email',
    rules: [
      {
        message: 'Email is required',
        required: true,
      },
    ],
    type: EDynamicFormField.EMAIL,
  },
  {
    name: 'button',
    placeholder: 'Confirm & Log In',
    rules: [
      {
        required: true,
      },
    ],
    title: 'Confirm & Log In',
    type: EDynamicFormField.PRIMARY_BUTTON,
  },
]
