import { Button, Text } from '@app/components'
import { MultiFactorInfo, PhoneMultiFactorInfo, TotpMultiFactorInfo } from 'firebase/auth'
import React from 'react'

export const MfaSelector: React.FC<{
  hints: MultiFactorInfo[]
  onSelect: (hint: MultiFactorInfo) => void
  recaptchaContainerRef: React.MutableRefObject<HTMLDivElement>
  submitting: boolean
  submittingHint?: MultiFactorInfo | null
}> = ({ hints, onSelect, recaptchaContainerRef, submitting, submittingHint }) => {
  const phoneNumberHint = hints.find((hint) => hint.factorId === 'phone') as PhoneMultiFactorInfo
  const totpHint = hints.find((hint) => hint.factorId === 'totp') as TotpMultiFactorInfo

  return (
    <>
      {!phoneNumberHint && !totpHint && (
        <Text>
          Something went wrong. You have an unsupported multi-factor authentication method enabled for your account.
        </Text>
      )}
      {(phoneNumberHint || totpHint) && (
        <>
          <Text>One more step before we let you in.</Text>
          {totpHint && (
            <>
              <Text>
                You have two-factor authentication enabled for your account. Please use the button below to enter the
                verification code from your authenticator app.
              </Text>
              <Button
                type="primary"
                onClick={() => onSelect(totpHint)}
                loading={submitting && submittingHint === totpHint}
                disabled={submitting}
              >
                Use authenticator app
              </Button>
            </>
          )}

          {phoneNumberHint && (
            <>
              {!totpHint && (
                <Text>
                  You have two-factor authentication enabled for your account. Please use the button below to send a
                  verification code to your phone number <b>{phoneNumberHint.phoneNumber}</b>.
                </Text>
              )}
              {totpHint && (
                <Text>
                  Or you can use the button below to send a verification code to your phone number{' '}
                  <b>{phoneNumberHint.phoneNumber}</b>.
                </Text>
              )}
              <Button
                type="primary"
                onClick={() => onSelect(phoneNumberHint)}
                loading={submitting && submittingHint === phoneNumberHint}
                disabled={submitting}
              >
                Send code in SMS
              </Button>
              <div ref={recaptchaContainerRef} />
            </>
          )}
        </>
      )}
    </>
  )
}
