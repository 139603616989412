import { MfaSelector, MfaVerification, useMfa } from '@app/collections/auth/mfa'
import { If, Link, Notification, Spacer, Text, useForm } from '@app/components'
import { ROUTE } from '@app/data'
import { DynamicForm, generateDynamicFields } from '@app/modules'
import { userUpdate } from '@app/redux'
import { auth, errorHandler } from '@app/services'
import { getMultiFactorResolver, MultiFactorError, signInWithEmailLink } from 'firebase/auth'
import { EMAIL_SIGN_IN_FORM } from 'libs/data/forms/EmailSignIn.form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { StyledRow, StyledText } from '../elements'
import { useSignInResult } from '../sign-in-sign-up'

export const AuthActionEmailSignIn: React.FC<{ href: string; continueUrl?: string }> = ({ href, continueUrl }) => {
  const dispatch = useDispatch()
  const [form] = useForm<{ email: string }>()
  const [userEmail, setUserEmail] = useState<string>()
  const [error, setError] = useState<string>()
  const initialEmail = useMemo(() => {
    try {
      return window.localStorage.getItem('emailForSignIn')
    } catch (err) {}
  }, [])
  const [mode, setMode] = useState<'init' | 'verify-email' | 'mfa' | 'mfa-verify' | 'invalid' | 'user-disabled'>(
    initialEmail ? 'init' : 'verify-email',
  )

  // continueUrl is a fully qualified URL and we need to extract the location part from it
  const continueLocation = useMemo(
    () => (continueUrl ? continueUrl.substring(new URL(continueUrl).origin.length) : ROUTE.BUY),
    [continueUrl],
  )

  const invite = useMemo(() => {
    return new URL(continueUrl).searchParams.get('invite')
  }, [continueUrl])

  const handleSignInResult = useSignInResult(continueLocation)

  const onSignedIn = useCallback(
    async (userCredential) => {
      try {
        window.localStorage.removeItem('emailForSignIn')
      } catch (err) {}

      await handleSignInResult(userCredential, { invite })
    },
    [continueLocation, handleSignInResult],
  )

  const handleMfaError = useCallback((err: Error) => {
    setError(err.message)
  }, [])

  const {
    hints,
    selectedHint,
    initMfa,
    startMfa,
    completeMfa,
    recaptchaContainerRef,
    submitting: mfaSubmitting,
  } = useMfa()

  const [submitting, setSubmitting] = useState(false)

  const handleError = (err: Error & { code: string }) => {
    const errCode = err.code
    if (errCode === 'auth/expired-action-code') {
      setMode('invalid')
      setError('The link has expired')
    } else if (errCode === 'auth/invalid-action-code') {
      setMode('invalid')
      setError('The link is invalid')
    } else if (errCode === 'auth/user-disabled' || errCode === 'auth/user-not-found') {
      setMode('user-disabled')
      setError('Cannot reset your password')
    } else if (errCode === 'auth/invalid-email') {
      if (initialEmail !== userEmail) {
        setMode('invalid')
        setError('The email is invalid or doesn\'t match our records')
      } else {
        setMode('verify-email')
      }
    } else if (errCode === 'auth/multi-factor-auth-required') {
      const resolver = getMultiFactorResolver(auth, err as MultiFactorError)
      initMfa(resolver)
      setMode('mfa')
    } else {
      setMode('invalid')
      setError('Something went wrong. Please try again')
      errorHandler.report(err)
    }
  }

  useEffect(() => {
    if (mode === 'init' && initialEmail) {
      signIn(initialEmail)
    }
  }, [mode])

  useEffect(() => {
    if (error) {
      Notification({ message: error, type: 'error' })
    }
  }, [error])

  const signIn = async (email: string) => {
    try {
      setSubmitting(true)
      dispatch(
        userUpdate({
          signUpInProgress: true,
        }),
      )

      const userCredential = await signInWithEmailLink(auth, email, href)
      onSignedIn(userCredential)
    } catch (err) {
      handleError(err)
      setSubmitting(false)
    }
    dispatch(
      userUpdate({
        signUpInProgress: false,
      }),
    )
  }

  const handleEmailVerification = async () => {
    try {
      setSubmitting(true)

      let email
      try {
        const data = await form.validateFields()
        email = data.email
      } catch (err) {
        setSubmitting(false)
        return
      }

      await signIn(email)
      setUserEmail(email)
    } catch (err) {
      Notification({ message: err.message, type: 'error' })
    }
  }

  return (
    <>
      <StyledRow>
        <StyledText>Sign in</StyledText>
      </StyledRow>

      <Spacer value={16} />

      <If condition={mode === 'init'}>
        <Text>Verifying code...</Text>
      </If>

      <If condition={mode === 'invalid'}>
        <>
          <Text>{error}</Text>
          <Link href={ROUTE.AUTH.SIGN_IN}>Sign in again</Link>
        </>
      </If>

      <If condition={mode === 'user-disabled'}>
        <Text>{error}</Text>
      </If>

      <If condition={mode === 'mfa'}>
        <MfaSelector
          hints={hints}
          onSelect={async (hint) => {
            try {
              await startMfa(hint)
              setMode('mfa-verify')
            } catch (err) {
              handleMfaError(err)
            }
          }}
          recaptchaContainerRef={recaptchaContainerRef}
          submitting={mfaSubmitting}
        />
      </If>

      <If condition={mode === 'mfa-verify'}>
        <MfaVerification
          hint={selectedHint}
          onSubmit={async (code) => {
            try {
              const userCredential = await completeMfa(code)
              onSignedIn(userCredential)
            } catch (err) {
              handleMfaError(err)
            }
          }}
          submitting={mfaSubmitting}
        />
      </If>

      <If condition={mode === 'verify-email'}>
        <>
          <Text>A quick security check before we let you in.</Text>
          <If condition={!!initialEmail}>
            <Text>
              It seems like you&apos;re trying to log in as a different user than the one who requested a log in link in
              this browser.
            </Text>
          </If>
          <If condition={!initialEmail}>
            <Text>
              It seems like you&apos;re trying to log in from a different browser than this link was sent from.
            </Text>
          </If>
          <Text>To confirm that this is intended please type in the email you&apos;ve used to log in:</Text>
          <DynamicForm
            fields={generateDynamicFields(EMAIL_SIGN_IN_FORM, [{ name: 'button', loading: submitting }])}
            form={form}
            onSubmit={handleEmailVerification}
          />
        </>
      </If>
    </>
  )
}
