import { Text } from '@app/components'
import { isNumber } from '@app/utils'
import { MultiDigitInput } from 'collections/multi-digit-input'
import { MultiFactorInfo, PhoneMultiFactorInfo, TotpMultiFactorInfo } from 'firebase/auth'
import React, { useCallback } from 'react'

const CODE_LENGTH = 6

export const MfaVerification: React.FC<{
  hint: MultiFactorInfo
  onSubmit: (code: string) => void
  submitting: boolean
}> = ({ onSubmit, hint, submitting }) => {
  const phoneNumberHint = hint.factorId === 'phone' && (hint as PhoneMultiFactorInfo)
  const totpHint = hint.factorId === 'totp' && (hint as TotpMultiFactorInfo)

  const onMfaCodeChange = useCallback(
    (code: string) => {
      if (code.length === CODE_LENGTH && isNumber(code)) {
        onSubmit(code)
      }
    },
    [onSubmit],
  )

  return (
    <>
      {!totpHint && !phoneNumberHint && (
        <Text>Something went wrong. This multi-factor authentication method is not supported.</Text>
      )}
      {(totpHint || phoneNumberHint) && (
        <>
          {hint.factorId === 'phone' && (
            <Text>
              Please enter the verification code sent to your phone number{' '}
              <b>{(hint as PhoneMultiFactorInfo).phoneNumber}</b>.
            </Text>
          )}
          {hint.factorId === 'totp' && <Text>Please enter the verification code from your authenticator app.</Text>}
          <MultiDigitInput lengthOfDigits={CODE_LENGTH} setCode={onMfaCodeChange} disabled={submitting} />
        </>
      )}
    </>
  )
}
