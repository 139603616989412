import { MfaSelector, MfaVerification, useMfa } from '@app/collections/auth/mfa'
import { If, Link, Notification, Spacer, Text, useForm } from '@app/components'
import { ROUTE } from '@app/data'
import { SIGN_IN_FORM } from '@app/forms'
import { DynamicForm, generateDynamicFields } from '@app/modules'
import { auth, errorHandler } from '@app/services'
import { fetchSignInMethodsForEmail, getMultiFactorResolver, MultiFactorError, SignInMethod } from 'firebase/auth'
import { useState } from 'react'

import { Logo, SignInGraphic } from '~public'

import {
  FullHeightCol,
  StyledCol,
  StyledContainer,
  StyledEmptyBoxRow,
  StyledFieldsRow,
  StyledLeftCol,
  StyledLinkRow,
  StyledRow,
  StyledText,
} from '../elements'
import { useSignInResult } from './useSignInResult'
import { useSignInSignUp } from './useSignInSignUp'

export const SignIn: React.FC = () => {
  const [mode, setMode] = useState<'signin' | 'mfa' | 'mfa-verify'>('signin')
  const [sentToEmail, setSentToEmail] = useState('')
  const [usePassword, setUsePassword] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submittingPrimary, setSubmittingPrimary] = useState(false)
  const [submittingSecondary, setSubmittingSecondary] = useState(false)

  const handleSignInResult = useSignInResult(ROUTE.BUY)

  const handleSignInError = (err: Error & { code: string }) => {
    const errCode = err.code
    if (errCode === 'auth/user-disabled' || errCode === 'auth/user-not-found') {
      Notification({ message: 'The account is invalid or disabled', type: 'error' })
    } else if (errCode === 'auth/multi-factor-auth-required') {
      const resolver = getMultiFactorResolver(auth, err as MultiFactorError)
      initMfa(resolver)
      setMode('mfa')
    } else {
      setSubmitting(false)
      setSubmittingPrimary(false)
      setSubmittingSecondary(false)
      Notification({ message: err.message, type: 'error' })
      errorHandler.report(err)
    }
  }

  const handleMfaError = (err: Error & { code: string }) => {
    const errCode = err.code
    if (errCode === 'auth/invalid-verification-code') {
      Notification({ message: 'The code is invalid', type: 'error' })
    } else {
      Notification({ message: err.message, type: 'error' })
      errorHandler.report(err)
    }
  }

  const {
    hints,
    selectedHint,
    completeMfa,
    initMfa,
    recaptchaContainerRef,
    startMfa,
    submitting: mfaSubmitting,
  } = useMfa()
  const { handleGoogleSignIn, handlePasswordSignIn, handlePasswordlessSignIn } = useSignInSignUp(false)

  const [form] = useForm()

  const handleFormSubmit = async (type?: string) => {
    setSubmitting(true)
    try {
      switch (type) {
        case 'google': {
          try {
            const userCredential = await handleGoogleSignIn()
            await handleSignInResult(userCredential)
          } catch (error) {
            handleSignInError(error)
          }
          break
        }
        case 'apple': {
          Notification({ type: 'error', message: 'Signing in with Apple is not supported' })
          break
        }
        case 'button': {
          let email
          let password
          try {
            const data = await form.validateFields(['email', ...(!usePassword ? [] : ['password'])])
            email = data.email
            password = data.password
          } catch (error) {
            setSubmitting(false)
            break
          }
          setSubmittingPrimary(true)
          if (usePassword) {
            try {
              const userCredential = await handlePasswordSignIn(email, password)
              await handleSignInResult(userCredential)
            } catch (error) {
              handleSignInError(error)
            }
          } else {
            await handleEmailSignIn(email)
            setSubmittingPrimary(false)
          }
          break
        }
        case 'link': {
          let email
          try {
            const data = await form.validateFields(['email'])
            email = data.email
          } catch (error) {
            setSubmitting(false)
            break
          }
          setSubmittingSecondary(true)
          await handlePasswordlessSignIn(email)
          setSentToEmail(email)
          break
        }
        default: {
          Notification({ type: 'error', message: 'Unsupported sign up method' })
          break
        }
      }
    } catch (error) {
      Notification({ type: 'error', message: error.message })
      setSubmitting(false)
      setSubmittingPrimary(false)
      setSubmittingSecondary(false)
      errorHandler.report(error)
    }
  }

  const handleEmailSignIn = async (email: string) => {
    const methods = await fetchSignInMethodsForEmail(auth, email)
    if (methods.length === 0) {
      throw new Error('No account found with this email')
    } else if (methods.includes(SignInMethod.EMAIL_PASSWORD)) {
      setUsePassword(true)
    } else {
      await handlePasswordlessSignIn(email)
      setSentToEmail(email)
    }
  }

  return (
    <>
      <StyledContainer>
        <StyledLeftCol span={12} xs={0} lg={12}>
          <StyledEmptyBoxRow>
            <SignInGraphic />
          </StyledEmptyBoxRow>
        </StyledLeftCol>

        <FullHeightCol span={12} xs={24} lg={12}>
          <StyledLinkRow>
            <StyledCol span={24}>
              <Text>
                Don`t have an account?&nbsp;
                <Link href={ROUTE.AUTH.SIGN_UP}>Sign-up</Link>
              </Text>
            </StyledCol>
          </StyledLinkRow>
          <StyledFieldsRow>
            <Logo />
            <Spacer value={35} />
            <StyledRow>
              <StyledText>Sign in to your Account</StyledText>
            </StyledRow>

            <If condition={mode === 'signin'}>
              <>
                <If condition={!sentToEmail}>
                  <DynamicForm
                    fields={generateDynamicFields(SIGN_IN_FORM, [
                      { name: 'password', hidden: !usePassword },
                      { name: 'forgotYourPassword', hidden: !usePassword },
                      {
                        name: 'button',
                        loading: submittingPrimary,
                        title: usePassword ? 'Sign in with a password' : 'Continue',
                      },
                      { name: 'google', hidden: usePassword },
                      { name: 'apple', hidden: usePassword },
                      { name: 'link', hidden: !usePassword, loading: submittingSecondary },
                    ])}
                    form={form}
                    onSubmit={handleFormSubmit}
                    disabled={submitting}
                  />
                </If>
                <If condition={sentToEmail}>
                  <Text>
                    We have sent a secure sign-in link to your email <b>{sentToEmail}</b>. Please check your inbox and
                    click on the link to finish signing in.
                  </Text>
                </If>
              </>
            </If>
            <If condition={mode === 'mfa'}>
              <MfaSelector
                hints={hints}
                onSelect={async (hint) => {
                  try {
                    await startMfa(hint)
                    setMode('mfa-verify')
                  } catch (err) {
                    Notification({ type: 'error', message: err.message })
                    errorHandler.report(err)
                  }
                }}
                recaptchaContainerRef={recaptchaContainerRef}
                submitting={mfaSubmitting}
                submittingHint={selectedHint}
              />
            </If>

            <If condition={mode === 'mfa-verify'}>
              <MfaVerification
                hint={selectedHint}
                onSubmit={async (code) => {
                  try {
                    const userCredential = await completeMfa(code)
                    await handleSignInResult(userCredential)
                  } catch (err) {
                    handleMfaError(err)
                  }
                }}
                submitting={mfaSubmitting}
              />
            </If>
          </StyledFieldsRow>
        </FullHeightCol>
      </StyledContainer>
    </>
  )
}
