import { Col, Row, Text } from '@app/components'
import { maxSmall } from '@app/styles'
import { ColProps, RowProps, TextProps } from '@app/types'
import styled from 'styled-components'

export const StyledRow = styled((props: RowProps) => <Row {...props} />)`
  align-items: center;
  justify-content: center;
`

export const StyledEmptyBoxRow = styled(StyledRow)`
  height: 100%;
  width: 100%;
`

export const StyledText = styled((props: TextProps) => <Text {...props} />)`
  font-size: 24px;
  font-weight: 500;
`

export const StyledContainer = styled((props: RowProps) => <Row {...props} />)`
  background: ${(props) => props.theme.color.baseBgColor};
  height: 100vh;
  overflow: auto;
`

export const StyledLeftCol = styled((props: ColProps) => <Col {...props} />)`
  border-right: 0.2px solid ${(props) => props.theme.color.dividerGrey};
`

export const StyledEmptyBox = styled((props: ColProps) => <Col {...props} />)`
  background: ${(props) => props.theme.color.grey};
  height: 400px;
  width: 400px;
`

export const StyledFieldsRow = styled((props: RowProps) => <Row {...props} />)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 150px;
  @media only screen and (max-width: ${maxSmall}) {
    padding: 30px;
  }
`

export const StyledCol = styled((props: ColProps) => <Col {...props} />)`
  text-align: right;
  width: 100%;
`

export const StyledLinkRow = styled((props: ColProps) => <Col {...props} />)`
  padding: 25px 25px 0px;
  width: 100%;
`

export const FullHeightCol = styled((props: ColProps) => <Col {...props} />)`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${StyledFieldsRow} {
    flex-grow: 1;
  }
`
