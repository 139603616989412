import { If, Link, Notification, Spacer, Text } from '@app/components'
import { ROUTE } from '@app/data'
import { auth, errorHandler } from '@app/services'
import { applyActionCode, checkActionCode } from 'firebase/auth'
import { useEffect, useMemo, useState } from 'react'

import { StyledRow, StyledText } from '../elements'

export const AuthActionVerifyEmail: React.FC<{ oobCode: string; continueUrl?: string }> = ({
  oobCode,
  continueUrl,
}) => {
  const [email, setEmail] = useState<string>()
  const [error, setError] = useState<string>()
  const [mode, setMode] = useState<'init' | 'invalid' | 'user-disabled' | 'success'>('init')

  // continueUrl is a fully qualified URL and we need to extract the location part from it
  const continueLocation = useMemo(
    () => (continueUrl ? continueUrl.substring(new URL(continueUrl).origin.length) : ROUTE.BUY),
    [continueUrl],
  )

  const handleError = (err: Error & { code: string }) => {
    const errCode = err.code
    if (errCode === 'auth/expired-action-code') {
      setMode('invalid')
      setError('The link has expired')
    } else if (errCode === 'auth/invalid-action-code') {
      setMode('invalid')
      setError('The link is invalid')
    } else if (errCode === 'auth/user-disabled' || errCode === 'auth/user-not-found') {
      setMode('user-disabled')
      setError('Cannot verify your email')
    } else {
      setMode('invalid')
      setError('Something went wrong. Please try again')
      errorHandler.report(err)
    }
  }

  const applyCode = async () => {
    try {
      const codeInfo = await checkActionCode(auth, oobCode)

      const {
        data: { email },
        operation,
      } = codeInfo

      if (operation !== 'VERIFY_EMAIL') {
        setMode('invalid')
        setError('Invalid code')
      } else {
        await applyActionCode(auth, oobCode)

        if (auth.currentUser) {
          await auth.currentUser.reload()
        }

        setEmail(email)
        setMode('success')
      }
    } catch (error) {
      handleError(error)
    }
  }

  useEffect(() => {
    if (mode === 'init') {
      applyCode()
    }
  }, [mode])

  useEffect(() => {
    if (error) {
      Notification({ message: error, type: 'error' })
    }
  }, [error])

  return (
    <>
      <StyledRow>
        <StyledText>Email verification</StyledText>
      </StyledRow>

      <Spacer value={16} />

      <If condition={mode === 'init'}>
        <Text>Verifying code...</Text>
      </If>

      <If condition={mode === 'invalid'}>
        <>
          <Text>Invalid or expired code. Please try again.</Text>
          <Link href={ROUTE.AUTH.VERIFY_EMAIL}>Request a new verification link</Link>
        </>
      </If>

      <If condition={mode === 'user-disabled'}>
        <Text>{error}</Text>
      </If>

      <If condition={mode === 'success'}>
        <>
          <Text>
            Your email <b>{email}</b> has been verified.
          </Text>
          <Link href={continueLocation}>Continue</Link>
        </>
      </If>
    </>
  )
}
