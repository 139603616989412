import { auth, Axios, errorHandler } from '@app/services'

export async function trackReferral(uid: string, referredByCode: string) {
  try {
    const authorization = await auth.currentUser.getIdToken()
    const headers = {
      Authorization: authorization,
    }

    await Axios.post(
      '/api/referrals/tracking',
      {
        uid,
        referredByCode,
      },
      { headers },
    )
  } catch (error) {
    errorHandler.report(`Error tracking referral: ${error}`)
  }
}
