import { Spacer, Text } from '@app/components'
import { ActionCodeOperation, parseActionCodeURL } from 'firebase/auth'
import { useMemo } from 'react'

import { Logo, SignInGraphic } from '~public'

import {
  FullHeightCol,
  StyledContainer,
  StyledEmptyBoxRow,
  StyledFieldsRow,
  StyledLeftCol,
  StyledRow,
  StyledText,
} from '../elements'
import { AuthActionEmailSignIn } from './AuthActionEmailSignIn'
import { AuthActionPasswordReset } from './AuthActionPasswordReset'
import { AuthActionRecoverSecondFactorAddition } from './AuthActionRecoverSecondFactorAddition'
import { AuthActionVerifyEmail } from './AuthActionVerifyEmail'

const supportedOperations = new Set([
  ActionCodeOperation.PASSWORD_RESET,
  ActionCodeOperation.VERIFY_EMAIL,
  ActionCodeOperation.EMAIL_SIGNIN,
  ActionCodeOperation.REVERT_SECOND_FACTOR_ADDITION,
])

export const AuthAction = () => {
  const url = window.location.href
  const parsedUrl = useMemo(() => parseActionCodeURL(url), [url])

  const valid = parsedUrl && supportedOperations.has(parsedUrl.operation as any)

  return (
    <StyledContainer>
      <StyledLeftCol span={12} xs={0} sm={0} md={0} lg={12}>
        <StyledEmptyBoxRow>
          <SignInGraphic />
        </StyledEmptyBoxRow>
      </StyledLeftCol>

      <FullHeightCol span={12} xs={24} sm={24} md={24} lg={12}>
        <StyledFieldsRow>
          <Logo />
          {valid && (
            <>
              {parsedUrl.operation === ActionCodeOperation.PASSWORD_RESET && (
                <AuthActionPasswordReset oobCode={parsedUrl?.code} continueUrl={parsedUrl?.continueUrl} />
              )}
              {parsedUrl.operation === ActionCodeOperation.VERIFY_EMAIL && (
                <AuthActionVerifyEmail oobCode={parsedUrl?.code} continueUrl={parsedUrl?.continueUrl} />
              )}
              {parsedUrl.operation === ActionCodeOperation.EMAIL_SIGNIN && (
                <AuthActionEmailSignIn href={url} continueUrl={parsedUrl?.continueUrl} />
              )}
              {parsedUrl.operation === ActionCodeOperation.REVERT_SECOND_FACTOR_ADDITION && (
                <AuthActionRecoverSecondFactorAddition oobCode={parsedUrl?.code} continueUrl={parsedUrl?.continueUrl} />
              )}
            </>
          )}

          {!valid && (
            <>
              <StyledRow>
                <StyledText>Invalid action</StyledText>
              </StyledRow>

              <Spacer value={35} />

              <Text>Invalid action code. Please try again.</Text>
            </>
          )}
        </StyledFieldsRow>
      </FullHeightCol>
    </StyledContainer>
  )
}
