import { Button, If, Notification, Spacer, Text } from '@app/components'
import { ROUTE } from '@app/data'
import { IStore } from '@app/redux'
import { EMAIL_TEMPLATES, errorHandler, sendEmail } from '@app/services'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Logo, SignInGraphic } from '~public'

import {
  FullHeightCol,
  StyledContainer,
  StyledEmptyBoxRow,
  StyledFieldsRow,
  StyledLeftCol,
  StyledRow,
  StyledText,
} from '../elements'

export const VerifyEmail: React.FC = () => {
  const [isEmailSent, setIsPasswordSent] = useState(false)
  const { user } = useSelector((state: IStore) => state)

  // eslint-disable-next-line no-console
  console.log('user', user)

  const email = user?.email

  const [loading, setLoading] = useState(false)

  const handleSendVerificationEmail = async () => {
    try {
      setLoading(true)

      const continueUrl = `${window.location.origin}${ROUTE.BUY}`

      await sendEmail(EMAIL_TEMPLATES.EMAIL_ADDRESS_VERIFICATION, email, { email, continueUrl })

      Notification({ message: `Verification email sent to ${email}`, type: 'success' })
      setTimeout(() => {
        setLoading(false)
        setIsPasswordSent(true)
      }, 1000)
    } catch (error) {
      Notification({ message: 'Something went wrong', type: 'error' })
      setLoading(false)
      errorHandler.report(error)
    }
  }

  return (
    <StyledContainer>
      <StyledLeftCol span={12} xs={0} sm={0} md={12}>
        <StyledEmptyBoxRow>
          <SignInGraphic />
        </StyledEmptyBoxRow>
      </StyledLeftCol>

      <FullHeightCol span={12} xs={24} sm={24} md={12}>
        <StyledFieldsRow>
          <Logo />
          <StyledRow>
            <StyledText>Verify Your Email</StyledText>
          </StyledRow>

          <If condition={!!email}>
            <>
              <Spacer value={35} />

              <If condition={!isEmailSent}>
                <>
                  <Text>
                    Please verify your email address <b>{email}</b> in order to access your account. If you have not
                    received the verification email, please check your spam folder or click the button below to resend
                    the email.
                  </Text>
                  <Spacer value={4} />
                  <Button type="primary" block loading={loading} onClick={handleSendVerificationEmail}>
                    Send Verification Email
                  </Button>
                </>
              </If>
              <If condition={isEmailSent}>
                <Text>
                  A verification link has been sent to your email <b>{email}</b>. Please check your inbox and follow the
                  instructions.
                </Text>
              </If>
            </>
          </If>
        </StyledFieldsRow>
      </FullHeightCol>
    </StyledContainer>
  )
}
