import { If, Link, Notification, Spacer, Text } from '@app/components'
import { ROUTE } from '@app/data'
import { auth, errorHandler } from '@app/services'
import { Button } from 'antd'
import { applyActionCode, checkActionCode } from 'firebase/auth'
import { PhoneMultiFactorInfo } from 'firebase-admin/auth'
import { useEffect, useMemo, useState } from 'react'

import { StyledRow, StyledText } from '../elements'

export const AuthActionRecoverSecondFactorAddition: React.FC<{ oobCode: string; continueUrl?: string }> = ({
  oobCode,
  continueUrl,
}) => {
  const [email, setEmail] = useState<string>()
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [error, setError] = useState<string>()
  const [mode, setMode] = useState<'init' | 'confirmation' | 'invalid' | 'user-disabled' | 'success'>('init')

  const [submitting, setSubmitting] = useState(false)

  // continueUrl is a fully qualified URL and we need to extract the location part from it
  const continueLocation = useMemo(
    () => (continueUrl ? continueUrl.substring(new URL(continueUrl).origin.length) : ROUTE.AUTH.SIGN_IN),
    [continueUrl],
  )

  const handleError = (err: Error & { code: string }) => {
    const errCode = err.code
    if (errCode === 'auth/expired-action-code') {
      setMode('invalid')
      setError('The link has expired')
    } else if (errCode === 'auth/invalid-action-code') {
      setMode('invalid')
      setError('The link is invalid')
    } else if (errCode === 'auth/user-disabled' || errCode === 'auth/user-not-found') {
      setMode('user-disabled')
      setError('Cannot reset your password')
    } else {
      setMode('invalid')
      setError('Something went wrong. Please try again')
      errorHandler.report(err)
    }
  }

  useEffect(() => {
    if (error) {
      Notification({ message: error, type: 'error' })
    }
  }, [error])

  const checkCode = async () => {
    try {
      const res = await checkActionCode(auth, oobCode)
      if (res.operation !== 'REVERT_SECOND_FACTOR_ADDITION') {
        throw new Error('Invalid code')
      }
      const multiFactorInfo = res.data.multiFactorInfo
      // TODO: support other second factors
      if (multiFactorInfo.factorId !== 'phone') {
        throw new Error('Invalid code')
      }
      setEmail(res.data.email)
      setPhoneNumber((res.data.multiFactorInfo as PhoneMultiFactorInfo).phoneNumber)
      setMode('confirmation')
    } catch (err) {
      handleError(err)
    }
  }

  useEffect(() => {
    if (mode === 'init') {
      checkCode()
    }
  }, [mode])

  const revokeSecondFactor = async () => {
    try {
      setSubmitting(true)
      await applyActionCode(auth, oobCode)

      if (auth.currentUser) {
        await auth.currentUser.reload()
      }

      setMode('success')
    } catch (err) {
      handleError(err)
    }
    setSubmitting(false)
  }

  return (
    <>
      <StyledRow>
        <StyledText>Revoke second factor</StyledText>
      </StyledRow>

      <Spacer value={16} />

      <If condition={mode === 'init'}>
        <Text>Verifying code...</Text>
      </If>

      <If condition={mode === 'confirmation'}>
        <>
          <Text>
            You've recently added a phone number <b>{phoneNumber}</b> as a second factor to your account <b>{email}</b>.
            If you didn't do this, revoke the second factor addition by clicking the button below.
          </Text>
          <Button type="primary" onClick={revokeSecondFactor} loading={submitting}>
            Revoke second factor
          </Button>
        </>
      </If>

      <If condition={mode === 'invalid'}>
        <>
          <Text>Invalid or expired code.</Text>
        </>
      </If>

      <If condition={mode === 'user-disabled'}>
        <Text>{error}</Text>
      </If>

      <If condition={mode === 'success'}>
        <>
          <Text>
            The phone number <b>{phoneNumber}</b> has been removed as a second factor from your account <b>{email}</b>.
          </Text>
          <Text>You may also want to reset your password if you suspect your account was compromised.</Text>
          <Link href={continueLocation}>Continue</Link>
        </>
      </If>
    </>
  )
}
