import { FlexContainer } from '@app/components'
import { maxSmall } from '@app/styles'
import { FlexContainerProps, InputProps } from '@app/types'
import styled from 'styled-components'

import { Input } from '../../libs/components/input/Input'

export const StyledInput = styled((props: InputProps) => <Input {...props} />)`
  border-radius: 3px;
  height: 60px;
  text-align: center;
  width: 65px;
`

export const StyledFlexContainer = styled((props: FlexContainerProps) => <FlexContainer {...props} />)`
  justify-content: space-between;
  gap: 16px;
  @media only screen and (max-width: ${maxSmall}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
