import { useSignInResult } from '@app/collections/auth/sign-in-sign-up/useSignInResult'
import { Alert, If, Link, Notification, Spacer, Text, useForm } from '@app/components'
import { ROUTE } from '@app/data'
import { SIGN_UP_FORM_NO_PASSWORD } from '@app/forms'
import { DynamicForm, generateDynamicFields } from '@app/modules'
import { userUpdate } from '@app/redux'
import { auth, errorHandler } from '@app/services'
import { fetchSignInMethodsForEmail } from 'firebase/auth'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Logo, SignInGraphic } from '~public'

import {
  FullHeightCol,
  StyledCol,
  StyledContainer,
  StyledEmptyBoxRow,
  StyledFieldsRow,
  StyledLeftCol,
  StyledLinkRow,
  StyledRow,
  StyledText,
} from '../elements'
import { useSignInSignUp } from './useSignInSignUp'

export const SignUp: React.FC = () => {
  const dispatch = useDispatch()
  const [form] = useForm()
  const [sentToEmail, setSentToEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const { query } = useRouter()
  const invite = query.invite as string | undefined

  const { handleGoogleSignIn, handlePasswordlessSignIn } = useSignInSignUp(true)
  const handleSignInResult = useSignInResult(ROUTE.BUY)

  const handleFormSubmit = async (type?: string) => {
    setSubmitting(true)
    try {
      dispatch(
        userUpdate({
          signUpInProgress: true,
        }),
      )

      switch (type) {
        case 'google': {
          const userCredential = await handleGoogleSignIn()
          await handleSignInResult(userCredential, { invite })
          break
        }
        case 'apple': {
          Notification({ type: 'error', message: 'Signing up with Apple is not supported' })
          break
        }
        case 'button': {
          let email
          try {
            const data = await form.validateFields()
            email = data.email
          } catch (error) {
            break
          }
          await handleEmailSignUp(email)
          break
        }
        default: {
          Notification({ type: 'error', message: 'Unsupported sign up method' })
        }
      }
    } catch (error) {
      Notification({ type: 'error', message: error.message })
      errorHandler.report(error)
    }
    dispatch(
      userUpdate({
        signUpInProgress: false,
      }),
    )
    setSubmitting(false)
  }

  const handleEmailSignUp = async (email: string) => {
    const methods = await fetchSignInMethodsForEmail(auth, email)
    if (methods.length === 0) {
      await handlePasswordlessSignIn(email, { invite })
      setSentToEmail(email)
    } else {
      throw new Error('An account with this email already exists')
    }
  }

  return (
    <StyledContainer>
      <StyledLeftCol span={12} xs={0} lg={12}>
        <StyledEmptyBoxRow>
          <SignInGraphic />
        </StyledEmptyBoxRow>
      </StyledLeftCol>

      <FullHeightCol span={12} xs={24} lg={12}>
        <StyledLinkRow>
          <StyledCol span={24}>
            <Text>
              Already have an account? <Link href={ROUTE.AUTH.SIGN_IN}>Sign-in</Link>
            </Text>
          </StyledCol>
        </StyledLinkRow>
        <StyledFieldsRow>
          <Logo />
          <Spacer value={25} />
          <StyledRow>
            <StyledText>Create an Account</StyledText>
          </StyledRow>

          {!invite && <Spacer value={25} />}
          {invite && (
            <Alert
              message={
                <Text>
                  You have been invited to join Coast. Create your account today and earn 5000 WAIT tokens when you
                  complete your first transaction.
                </Text>
              }
              type="success"
            />
          )}

          <If condition={sentToEmail}>
            <Text>
              We have sent a verification link to your email <b>{sentToEmail}</b>. Please check your inbox and click on
              the link to finish your account creation.
            </Text>
          </If>

          <If condition={!sentToEmail}>
            <DynamicForm
              fields={generateDynamicFields(SIGN_UP_FORM_NO_PASSWORD, [{ name: 'button', loading: submitting }])}
              form={form}
              onSubmit={handleFormSubmit}
              disabled={submitting}
            />
          </If>
        </StyledFieldsRow>
      </FullHeightCol>
    </StyledContainer>
  )
}
