import { trackReferral } from '@app/collections/referrals/tracking'
import { ROUTE } from '@app/data'
import { addUser } from '@app/services'
import { getAdditionalUserInfo, UserCredential } from 'firebase/auth'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export const useSignInResult = (continueUrl: string) => {
  const router = useRouter()

  const handleSignInResult = useCallback(
    async (userCredential: UserCredential, params?: { invite?: string }) => {
      const userInfo = await getAdditionalUserInfo(userCredential)

      if (userInfo.isNewUser) {
        const { uid, email } = userCredential.user
        await addUser(uid, { email })
        if (params?.invite) {
          await trackReferral(uid, params.invite)
        }
        router.replace(ROUTE.ACCOUNT_REGISTRATION)
      } else {
        router.replace(continueUrl)
      }
    },
    [continueUrl],
  )

  return handleSignInResult
}
